import { CrashFileQueryItemDTO } from '@/api/dto/crash-file.dto';
import { RBTagQueryDTO, TagMatch } from '@/api/dto/report';
import { UserStatuses } from '@/api/dto/user';
import { fetchCrashFileList } from '@/api/report-builder';
import { alertError } from '@/helpers/alert-error';
import { SortDirection } from '@/helpers/data-query';
import { FetchStates } from '@/helpers/fetch-states';
import { OrganizationsModule } from '@/store/organizations';
import { computed, ref, watch } from 'vue';

export function useCrashFileList() {
  const crashFiles = ref<CrashFileQueryItemDTO[]>([]);
  const fetchState = ref<FetchStates>(FetchStates.UNFETCHED);
  const returnCount = ref(0);
  const total = ref(0);
  const page = ref(1);
  const pageSize = ref(100);
  const sortDir = ref<SortDirection>(SortDirection.DESC);
  const sortBy = ref<keyof CrashFileQueryItemDTO>('date_created');
  const orgIds = computed(() => OrganizationsModule.activeOrgIds);
  const searchStr = ref<string>('');
  const searchStatus = ref<boolean | undefined>(undefined);
  const trainingStatus = ref<boolean | undefined>(undefined);
  const tagQueries = ref<RBTagQueryDTO[]>([]);
  const totalTagQueries = computed(() => tagQueries.value.length);
  const tagMatch = ref<TagMatch>(TagMatch.some);
  const organizationGroup = ref<string | undefined>(undefined);

  const fetchCrashFiles = async () => {
    if (orgIds.value.length < 1) {
      crashFiles.value = [];
      returnCount.value = 0;
      total.value = 0;
      page.value = 1;

      return;
    }

    fetchState.value = FetchStates.FETCHING;
    try {
      const data = await fetchCrashFileList({
        org_ids: orgIds.value,
        tags: tagQueries.value,
        tagMatch: tagMatch.value,
        sort_by: sortBy.value,
        sort_dir: sortDir.value,
        active: searchStatus.value,
        training: trainingStatus.value,
        organization_groups: organizationGroup.value,
        search_str: searchStr.value,
        limit: pageSize.value,
        offset: (page.value - 1) * pageSize.value,
      });

      crashFiles.value = data.crashFiles;
      total.value = data.total;

      fetchState.value = FetchStates.FETCHED;
    } catch (error) {
      alertError(error, 'There was an error fetching crash files.');
      console.error(error);
      fetchState.value = FetchStates.UNFETCHED;
    }
  };

  const onSortChanged = ({
    order,
    prop,
  }: {
    order: 'ascending' | 'descending';
    prop: keyof CrashFileQueryItemDTO;
    column: any;
  }) => {
    page.value = 1;
    sortBy.value = prop;

    if (order === 'descending') sortDir.value = SortDirection.DESC;
    else sortDir.value = SortDirection.ASC;
  };

  const statusFilterHandler = (statusRadio: UserStatuses) => {
    switch (statusRadio) {
      case UserStatuses.ALL:
        searchStatus.value = undefined;
        break;
      case UserStatuses.ACTIVE:
        searchStatus.value = true;
        break;
      case UserStatuses.DELETED:
        searchStatus.value = false;
        break;
    }
  };

  const trainingStatusFilterHandler = (status: string) => {
    switch (status) {
      case '':
        trainingStatus.value = undefined;
        break;
      case '1':
        trainingStatus.value = true;
        break;
      case '0':
        trainingStatus.value = false;
        break;
    }
  };

  const searchChangeHandler = (search: string) => {
    searchStr.value = search;
  };

  const tagChangeHandler = (tags: RBTagQueryDTO[]) => {
    tagQueries.value = tags;
  };

  const tagMatchChangeHandler = (match: TagMatch) => {
    tagMatch.value = match;
  };

  const organizationGroupChangeHandler = (group: string) => {
    organizationGroup.value = group;
  };

  watch(page, fetchCrashFiles);
  watch(pageSize, fetchCrashFiles);
  watch(sortBy, fetchCrashFiles);
  watch(sortDir, fetchCrashFiles);
  watch(orgIds, fetchCrashFiles);
  watch(searchStatus, fetchCrashFiles);
  watch(trainingStatus, fetchCrashFiles);
  watch(searchStr, fetchCrashFiles);
  watch(totalTagQueries, fetchCrashFiles);
  watch(tagMatch, fetchCrashFiles);
  watch(organizationGroup, fetchCrashFiles);

  fetchCrashFiles();

  return {
    crashFiles,
    fetchState,
    returnCount,
    total,
    page,
    pageSize,
    sortDir,
    sortBy,
    orgIds,
    searchStr,
    searchStatus,
    trainingStatus,
    tagQueries,
    totalTagQueries,
    tagMatch,
    fetchCrashFiles,
    onSortChanged,
    statusFilterHandler,
    trainingStatusFilterHandler,
    searchChangeHandler,
    tagChangeHandler,
    tagMatchChangeHandler,
    organizationGroup,
    organizationGroupChangeHandler,
  };
}
