
import CrashFilesList from '../components/crash-files/CrashFileList.vue';
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'CrashFilesListPage',
  components: { CrashFilesList },
  setup() {
    return {};
  },
});
