
import { defineComponent, onMounted, ref, Ref } from 'vue';
import { useCrashFileList } from '@/composables/useCrashFileList';
import { FetchStates } from '@/helpers/fetch-states';
import { useOrgManager } from '@/composables/useOrgManager';
import { convertDateString } from '@/helpers/date-format';
import { UserPrefsModule } from '@/store/userprefs';
import { UserPrefs } from '@/api/dto/user-prefs';
import { RBTagQueryDTO, TagMatch } from '@/api/dto/report';
import { UserStatuses } from '@/api/dto/user';
import SearchBar from '../search/SearchBar.vue';
import { requestCrashFileExport, downloadExport } from '@/api/report-builder';
import { useAuth } from '@/composables/useAuth';
import {AuthModule} from "@/store/auth";

export default defineComponent({
  name: 'CrashFileList',
  components: {
    SearchBar,
  },
  setup() {
    const search = ref<string>('');
    const { orgMap } = useOrgManager();

    const userPrefs: Ref<UserPrefs | null> = ref(null);
    onMounted(async () => {
      userPrefs.value = await UserPrefsModule.getUserPrefs();
    });

    const downloadCrashFile = async (crashfileId: string) => {
      const exportReq = await requestCrashFileExport(crashfileId);
      downloadExport(exportReq.nonce);
    };

    const { isVAR, isSuperAdmin } = useAuth();

    const getName = (row: any) => {
      let tags = JSON.parse(JSON.stringify(row))['tags']
      let first_name = '';
      let last_name = ''
      tags.forEach((tag: { key: string; value: string; })=>{
        if(tag.key==='user_first_name') first_name = tag.value;
        if(tag.key==='user_last_name') last_name = tag.value;
      });
      return `${first_name} ${last_name}`;
    }

    const getGroup = (row: any) => {
      let tags = JSON.parse(JSON.stringify(row))['tags']
      let groups = '';
      tags.forEach((tag: { key: string; value: string; })=>{
        if (tag.key === 'user_organization_groups') groups = tag.value;
      });
      return groups;
    }

    return {
      search,
      ...useCrashFileList(),
      orgMap,
      userPrefs,
      FetchStates,
      convertDateString,
      downloadCrashFile,
      isVAR,
      isSuperAdmin,
      getName,
      getGroup,
      account: AuthModule.account,
    };
  },
  methods: {
    atSearchChange(val: string) {
      this.searchChangeHandler(val);
    },
    atStatusChange(val: UserStatuses) {
      this.statusFilterHandler(val);
    },
    atTrainingStatusChange(val: string) {
      this.trainingStatusFilterHandler(val);
    },
    atTagQueryChange(val: RBTagQueryDTO[]) {
      this.tagChangeHandler(val);
    },
    atTagMatchChange(val: TagMatch) {
      this.tagMatchChangeHandler(val);
    },
    atOrganizationGroupChange(val: string) {
      this.organizationGroupChangeHandler(val);
    },
  },
});
